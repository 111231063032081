import React, { useState, useEffect } from 'react'
import Profile from '../Profile/Profile'
import './Applications.css'

export default function ProfileModal({ professionals, loading, currentTab, setCurrentTab, searchProfessionals }) {

    const renderProfessionals = () => {
        if (loading) {
            return Array(6).fill(0).map(() => (
                <Profile loading={loading} />
            ))
        } else if (professionals.length == 0) return <div className='noProfessionals'>No Applications to Show</div>
        else {
            return professionals.map((professional, index) => (
                <Profile data={professional} key={index} loading={loading} searchProfessionals={searchProfessionals} />
            ))
        }
    }

    return (
        <div className='Applications__Main'>
            <div className='Applications__Tabs'>
                <div className={currentTab !== 'inactive' ? 'Applications__Tab' : 'Applications__Tab__Selected'} onClick={() => setCurrentTab('inactive')}>New Applications</div>
                <div className={currentTab !== 'pending' ? 'Applications__Tab' : 'Applications__Tab__Selected'} onClick={() => setCurrentTab('pending')}>Pending Set-Up</div>
            </div>
            <div className='Applications__Container'>
                {renderProfessionals()}
            </div>

        </div>
    )
}
