import React from 'react'
import { Link } from 'react-router-dom'
import ModalWrapper from '../ModalWrapper/ModalWrapper'
import './ApplicationResultModal.css'
import ProfessionalsIcon from '../../assets/Icons/Briefcase.png'
import File from '../File/File'
import ApprovedIcon from '../../assets/Icons/Approved.png'
import DeniedIcon from '../../assets/Icons/Denied.png'

export default function ApplicationResultModal({ open, setOpen, approved }) {
    return (
        <ModalWrapper open={open} setOpen={setOpen} width='440px'>
            {approved
                ? <div className='ResultModal__Main'>
                    <div className='ResultModal__Icon' style={{ backgroundImage: `url(${ApprovedIcon})` }} />
                    <div className='ResultModal__Title'>Professional Accepted</div>
                    <div className='ResultModal__Body'>This application has been accepted.</div>

                    <button className='BlueButton__Full' onClick={() => setOpen(false)}>Great!</button>
                </div>

                : <div className='ResultModal__Main'>
                    <div className='ResultModal__Icon' style={{ backgroundImage: `url(${DeniedIcon})` }} />
                    <div className='ResultModal__Title'>Application Denied</div>
                    <div className='ResultModal__Body'>This application has been declined.</div>

                    <button className='BlueButton__Full' onClick={() => setOpen(false)}>OKAY</button>
                </div> }
        </ ModalWrapper>
    )
}
