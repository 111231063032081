import React, { useState, useEffect, FunctionComponent } from 'react'
import Profile from '../Profile/Profile'
import './Professionals.css'
import { Professional } from '../../Types/types'
import ProfileModal from '../ProfileModal/ProfileModal'

type Props = {
	professionals: Professional[]
	loading: boolean
}

const Professionals: FunctionComponent<Props> = ({ professionals, loading }) => {
	const [isProfileOpen, setIsProfileModalOpen] = useState<boolean>(false)
	const [professionalData, setProfessionalData] = useState<Professional>()

	const renderSpecialties = (specialties: { specialty: string }[]) => {
		return specialties.map((specialty1, index) => {
			return (
				<h5 style={{ textOverflow: 'ellipsis', lineHeight: 0.5, flexDirection: 'row' }} key={index}>
					{specialty1.specialty}
					{index !== specialties.length - 1 && ','}&nbsp;
				</h5>
			)
		})
	}

	const renderHeaders = () => {
		return (
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<div className='Professionals_Details'>
					<h4 style={{ textOverflow: 'ellipsis', textAlign: 'center' }}>Full Name</h4>
				</div>
				<div className='Professionals_Details'>
					<h4 style={{ textOverflow: 'ellipsis', textAlign: 'center' }}>Email</h4>
				</div>
				<div className='Professionals_Details' style={{ width: 200, maxWidth: 200 }}>
					<h4 style={{ textOverflow: 'ellipsis', textAlign: 'center' }}>MARA/LPN number</h4>
				</div>
				<div className='Professionals_Details' style={{ width: 150, maxWidth: 150 }}>
					<h4 style={{ textOverflow: 'ellipsis', textAlign: 'center' }}>Mobile</h4>
				</div>
				<div className='Professionals_Details' style={{ width: 400, maxWidth: 400 }}>
					<h4 style={{ textOverflow: 'ellipsis', textAlign: 'center' }}>Specialties</h4>
				</div>
				<div className='Professionals_Details' style={{ width: 200, maxWidth: 200, borderRightWidth: 1 }}>
					<h4 style={{ textOverflow: 'ellipsis', textAlign: 'center' }}>Status</h4>
				</div>
			</div>
		)
	}

	const mapProfessionals = () => {
		return professionals.map((professional, index) => {
			return (
				<div style={{ display: 'flex', flexDirection: 'row', backgroundColor: index % 2 == 0 ? '#8aafeb' : 'white' }} key={index}>
					<div className='Professionals_Details'>
						<h4
							onClick={() => [setProfessionalData(professional), setIsProfileModalOpen(true)]}
							style={{ textOverflow: 'ellipsis', cursor: 'pointer' }}
						>
							{professional.firstName} {professional.lastName}
						</h4>
					</div>
					<div className='Professionals_Details'>
						<h4 style={{ textOverflow: 'ellipsis' }}>{professional.email}</h4>
					</div>
					<div className='Professionals_Details' style={{ width: 200, maxWidth: 200 }}>
						<h4 style={{ textOverflow: 'ellipsis' }}>
							{professional.MARANumber ? `M: ${professional.MARANumber}` : `L: ${professional.LPNNumber}`}
						</h4>
					</div>
					<div className='Professionals_Details' style={{ width: 150, maxWidth: 150 }}>
						<h4 style={{ textOverflow: 'ellipsis' }}>{professional.mobile}</h4>
					</div>
					<div className='Professionals_Details' style={{ width: 400, maxWidth: 400, display: 'flex', flexDirection: 'row' }}>
						{renderSpecialties(professional.professionalspecialties)}
					</div>
					<div className='Professionals_Details' style={{ width: 200, maxWidth: 200, borderRightWidth: 1 }}>
						<h4 style={{ textOverflow: 'ellipsis' }}>{professional.status}</h4>
					</div>
				</div>
			)
		})
	}

	return (
		<div className='Professionals__Main'>
			<div className='Professionals__Container1'>
				{renderHeaders()}
				{mapProfessionals()}
				{professionalData && isProfileOpen && (
					<ProfileModal open={isProfileOpen} setOpen={setIsProfileModalOpen} professional={professionalData} />
				)}
			</div>
		</div>
	)
}

export default Professionals
