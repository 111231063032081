import './App.css'
import React, { useReducer, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import reducer from './utils/reducer'
import Home from './pages/Home/Home'
import ApplicationsPage from './pages/ApplicationsPage/ApplicationsPage'
import ReportsPage from './pages/ReportsPage/ReportsPage'
import Authentication from './pages/Authentication/Authentication'
import ClientsPage from './pages/Clients/ClientsPage'
import ClientProfile from './pages/ClientProfile/ClientProfile'

export const GlobalStateContext = React.createContext()

const initialState = {}

function App() {
	const [state, dispatch] = useReducer(reducer, initialState)
	const [loadingUser, setLoadingUser] = useState(true)

	useEffect(() => {
		const token = localStorage.getItem('TFAAdminToken')
		if (!token) {
			setLoadingUser(false)
			return
		}
	}, [])

	function AuthRoute({ component: Component, ...rest }) {
		const token = localStorage.getItem('TFAAdminToken')
		return (
			<Route
				{...rest}
				render={(props) => (token ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />)}
			/>
		)
	}

	function AuthRedirectRoute({ component: Component, ...rest }) {
		const token = localStorage.getItem('TFAAdminToken')
		return (
			<Route
				{...rest}
				render={(props) =>
					token ? <Redirect to={{ pathname: '/Professionals', state: { from: props.location } }} /> : <Component {...props} />
				}
			/>
		)
	}

	return (
		<GlobalStateContext.Provider value={{ state, dispatch }}>
			<Router>
				<Switch>
					<AuthRoute exact path='/Professionals' component={Home} />
					<AuthRoute exact path='/Clients' component={ClientsPage} />
					<AuthRoute exact path='/ClientProfile/:id' component={ClientProfile} />
					<AuthRoute exact path='/Applications' component={ApplicationsPage} />
					<AuthRoute exact path='/Reports' component={ReportsPage} />
					<AuthRedirectRoute exact path='/' component={Authentication} />
				</Switch>
			</Router>
		</GlobalStateContext.Provider>
	)
}

export default App
