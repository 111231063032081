import React, { FunctionComponent, useEffect, useState } from 'react'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import { ProfessionalService } from '../../api/Professionals'
import { Question } from '../../Types/types'
import PDFIcon from '../../assets/Icons/PDFIcon.svg'
import './QuestionsCard.css'
import File from '../File/File'

type Props = {
    question: Question
}

const QuestionsCard: FunctionComponent<Props> = ({ question }) => {
    const [images, setImages] = useState<string[]>([])
    const [files, setFiles] = useState<string[]>([])

    useEffect(() => {
        const getQuestionImages = async () => {
            const data = await Promise.all(question.questionimages.map(async(image) => {
                return await ProfessionalService.downloadFile(image.key)
            }))
            setImages(data)
        }
        const getQuestionFiles = async () => {
            const data = await Promise.all(question.questionfiles.map(async(file) => {
                return await ProfessionalService.downloadFile(file.key)
            }))
            console.log("file data", data)
            setFiles(data)
        }
        getQuestionFiles()
        getQuestionImages()
    },[])

    const mapQuesitonImages = () => {
        if (question.questionimages.length === 0) {
            return <div>No Images</div>
        }
        return (
                <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                    {images.map((image, index) => {
                        return <img className='Question_Images' key={index} src={image}/>
                    })}
                </ScrollMenu>
        )
    }

    function LeftArrow() {
        const { isFirstItemVisible, scrollPrev } =
          React.useContext(VisibilityContext);
      
        return (
          <button className='Question_ScrollButtons' disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
            {'<'}
          </button>
        );
      }
      
      function RightArrow() {
        const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
      
        return (
          <button className='Question_ScrollButtons' disabled={isLastItemVisible} onClick={() => scrollNext()}>
            {'>'}
          </button>
        );
      }

    const mapQuesitonFiles = () => {
        if (question.questionfiles.length === 0) {
            return <div >No Files</div>
        }
        return question.questionfiles.map((file, index) => {
            return (
                <div className='PDF_style'>
                    <File fileName={file.key.substring(25)} file={files[index]}/>
                </div>
            )
        })
    }

    const mapQuesitonReplies = () => {
        if (question.questionreplies.length === 0) {return <div>No Replys Yet</div>}
        const firstProfessional = question.questionreplies.filter((reply) => reply.stage === 'professionalReplyOne')[0]
        const firstClient = question.questionreplies.filter((reply) => reply.stage === 'clientReplyOne')[0]
        const secondProfessional = question.questionreplies.filter((reply) => reply.stage === 'professionalReplyTwo')[0]
        console.log("firs preofwssdsion", firstProfessional)
        return (
            <>
                <div className='Replies_Stage_Title'>{question.professional.firstName} {question.professional.lastName}:</div>
                    <div>{firstProfessional.content}</div>
                {firstClient && 
                    <>
                    <div className='Replies_Stage_Title'>You:</div>
                        <div>{firstClient.content}</div>
                    </>}
                {secondProfessional && 
                    <>
                    <div className='Replies_Stage_Title'>{question.professional.firstName} {question.professional.lastName}:</div>
                        <div>{secondProfessional.content}</div>
                    </>}
            </>
        )
    }

    return (
        <div className='Question_Content_Container'>
            <div className='Question_Content_Title'>Title: <div className='Question_Content_Text'>{question.title}</div></div>

            <div className='Question_Content_Title'>Content: <div className='Question_Content_Text'>{question.content}</div></div>

            <div className='Question_Content_Title'>Category: <div className='Question_Content_Text'>{question.category}</div></div>

            <div className='Question_Content_Title'>Destination: <div className='Question_Content_Text'>{question.destinationCountry}</div></div>

            <div className='Question_Content_Title'>Progress: <div className='Question_Content_Text'>{question.progress}</div></div>

            <div className='Question_Content_Title'>Price: ${parseInt(question.price)/100}</div>
            <div className='Question_Content_Title'>Professional: <div className='Question_Content_Text'>{question.professional ? `${question.professional.firstName} ${question.professional.lastName}` : 'No Professional'}</div></div>
            <div className='Question_Files_Title'>Images:</div>
            {mapQuesitonImages()}
            <div className='Question_Files_Title'>Files:</div>
            {mapQuesitonFiles()}
            <div className='Question_Replys_Title'>Replys:</div>
            {mapQuesitonReplies()}
        </div>
    )
}

export default QuestionsCard
