import React, { useState } from 'react'
import PanelOption from '../PanelOption/PanelOption'
import './SidePanel.css'
import ProfessionalsIcon from '../../assets/Icons/Briefcase.png'
import ApplicationsIcon from '../../assets/Icons/Applications.png'
import Logout from '../../assets/Icons/Logout.png'
import ConfirmSignOut from '../ConfirmSignOut/ConfirmSignOut'
import ReportsIcon from '../../assets/Icons/Flag.png'

export default function SidePanel({ currentPage }) {

    const [signout, setSignout] = useState(false)
    const token = localStorage.getItem('TFAAdminToken')

    return (
        <div className='SidePanel__Main'>
            <div className='SidePanel__LogoContainer'> <div className='SidePanel__Logo'/> </div>
            <PanelOption title={'Professional Management'} option={'Professionals'} icon={ProfessionalsIcon} link={'/Professionals'} currentPage={currentPage === 'Professionals'}/>
            <PanelOption title={'Client Management'} option={'Clients'} icon={ProfessionalsIcon} link={'/Clients'} currentPage={currentPage === 'Clients'}/>
            <PanelOption title={'Resources'} option={'Applications'} icon={ApplicationsIcon} link={'/Applications'} currentPage={currentPage === 'Applications'} />
            <PanelOption title={'Maintenance'} option={'Reports'} icon={ReportsIcon} link={'/Reports'} currentPage={currentPage === 'Reports'} />
            <PanelOption title={'Account'} option={'Logout'} icon={Logout} setSignout={setSignout} isButton={true}/>
            <ConfirmSignOut open={signout} setOpen={setSignout} />
        </div>
    )
}
