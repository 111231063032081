import axios from 'axios'
import Instance from '../utils/Axios'

export const ClientService = {

    getClients: async (email) => {
        console.log("email", email)
        try {
            const { data } = await Instance.post('', {
                query: `query ObjectList($email: String) {
                  GetClients(email: $email) {
                    objectList {
                      id
                      firstName
                      lastName
                      avatar
                      email
                      mobile
                      countryOfOrigin
                      firstLanguage
                      secondLanguage
                    }
                  }
                }`,
                  variables: {
                    email,
                  }
            })
            console.log("clinet data", data.data.GetClients.objectList)
            return data.data.GetClients.objectList

        } catch (error) {
            console.log(error)
            if (axios.isAxiosError(error)) throw Error('Network')
            throw Error(error.message)
        }
    },

    getClientQuestions: async (clientId) => {
      console.log("client id, ", clientId)
        try {
            const { data } = await Instance.post('', {
                query: `query GetQuestions($clientId: String, $progress: [QuestionProgressEnumType]) {
                  GetQuestions(clientId: $clientId, progress: $progress) {
                    objectList {
                      title
                      content
                      destinationCountry
                      category
                      price
                      clientExperience
                      progress
                      questionimages {
                        key
                      }
                      questionfiles {
                        key
                      }
                      questionreplies {
                        content
                        progress
                        questionreplyfiles {
                          key
                        }
                        createdAt
                        stage
                      }
                      client {
                        firstName
                        lastName
                      }
                      professional {
                        firstName
                        lastName
                        MARANumber
                      }
                    }
                    count
                  }
                }`,
                  variables: {
                    clientId,
                    progress: ["Posted", "Answered", "Complete", "Pick_Up", "Working"]
                  }
            })
            console.log("clinet data", data.data.GetQuestions.objectList)
            return data.data.GetQuestions.objectList

        } catch (error) {
            console.log(error)
            if (axios.isAxiosError(error)) throw Error('Network')
            throw Error(error.message)
        }
    },

    getClient: async (getClientId) => {
      try {
          const { data } = await Instance.post('', {
              query: `query GetClient($getClientId: String) {
                GetClient(id: $getClientId) {
                  firstName
                  lastName
                  email
                  mobile
                  avatar
                  countryOfOrigin
                  firstLanguage
                  secondLanguage
                }
              }`,
                variables: {
                  getClientId,
                }
          })
          console.log("clinet data", data.data.GetClient)
          return data.data.GetClient

      } catch (error) {
          console.log(error)
          if (axios.isAxiosError(error)) throw Error('Network')
          throw Error(error.message)
      }
  },



}