import axios from 'axios'
import Instance from '../utils/Axios'

export const AuthService = {
    signInAdmin: async (account, password) => {
        try {
            const { data } = await Instance.post('', {
                query: `mutation AdminLogin($account: String, $password: String) {
                    AdminLogin(account: $account, password: $password) {
                      id
                      JWT
                    }
                  }`,
                variables: {
                    account,
                    password
                }
            })

            if (!data.data.AdminLogin) throw Error('Not Found')
            localStorage.setItem('TFAAdminToken', data.data.AdminLogin.JWT)
            return data.data.AdminLogin.JWT

        } catch (error) {
            console.log(error)
            if (axios.isAxiosError(error)) throw Error('Network')
            throw Error(error.message)
        }
    },

    signOutAdmin: async () => {
        try {

        } catch (error) {
            console.log(error)
            if (axios.isAxiosError(error)) throw Error('Network')
            throw Error(error.message)
        }
    },

    getUser: async () => {
        try {
            const { data } = await Instance.post('', {
                query: `query Root_query_type_v1 {
                    GetVisitorToken
                  }`,
            })

            if (!data.data.getVisitorToken) throw Error('Not Found')
            return data.data.getVisitorToken

        } catch (error) {
            console.log(error)
            if (axios.isAxiosError(error)) throw Error('Network')
            throw Error(error.message)
        }
    },

}