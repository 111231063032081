import React, { useState, useEffect, FunctionComponent } from 'react'
import { ReportService } from '../../api/Reports'
import Profile from '../Profile/Profile'
import './EmailComp.css'

type Props = {
    receiver: string
    onSend: () => void
}

const EmailComp: FunctionComponent<Props> = ({ receiver, onSend }) => {
    const [emailBody, setEmailBody] = useState('')

    const sendEmail = async () => {
        try {
            await ReportService.sendEmail(receiver, emailBody)
            onSend()
        } catch (error) {
            console.log("error", error)
        }
    }
    return (
            <div>
                <textarea value={receiver} className='Email_Input1' placeholder='Email' name="name"/>
                <textarea value={'ken@timeforadvice.com'} className='Email_Input1'  placeholder='Email' name="mail"/>
                <textarea style={{width: "100%", height:"200px"}} value={emailBody} onChange={(value) => setEmailBody(value.target.value)} className='Email_Input2' placeholder='Content' name="comment"/>
                <button className='BlueButton__Full' type="submit" value="Send" onClick={sendEmail}>Send</button>
                <button className='BlueButton__Full' type="reset" value="Reset" onClick={() => setEmailBody('')}>reset</button>
            </div>
    )
}

export default EmailComp
