import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Box from '@mui/material/Box'

export default function ModalWrapper({ open, setOpen, width, minWidth, children, maxWidth }) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        minWidth: minWidth ?? '400px',
        maxWidth: maxWidth ?? 'none',
        outline: 'none',
        maxHeight: 'calc(100vh)',
        overflowY: 'auto'


      }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            // disableEnforceFocus
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                sx: {
                    backgroundColor: 'rgba(0,0,0, 0.25)',
                }
            }}
        >
            <Fade in={open} timeout={500}>
                <Box sx={style}>
                    {children}
                </Box>
            </Fade>
        </Modal>
    )
}
