import React, { useState, useEffect } from 'react'
import Header from '../../components/Header/Header'
import SidePanel from '../../components/SidePanel/SidePanel'
import './ClientsProfile.css'
import ProfessionalsIcon from '../../assets/Icons/Briefcase.png'
import asyncTimeout from '../../utils/asyncTimeout'
import { ClientService } from '../../api/Client'
import Clients from '../../components/Clients/Clients.tsx'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams
} from 'react-router-dom'
import QuestionsCard from '../../components/QuestionsCard/QuestionsCard'
import { AuthService } from '../../api/Auth'
import { ProfessionalService } from '../../api/Professionals'
// import { useParams } from 'react-router';
import EmptyAvatar from '../../assets/Icons/EmptyAvatar.svg'
import { LocalActivityRounded } from '@material-ui/icons'

export default function ClientProfile() {

    const [questions, setQuestions] = useState([])
    const [loading, setLoading] = useState()
    const [user, setUser] = useState('')
    const [avatar, setAvatar] = useState()
    const { id } = useParams();


    const getUserName = async () => {
        try {
            const userName = await ClientService.getClient(id)
            console.log("user", userName)
            setUser(userName)
            console.log("user avata askodnasdnasjndas", userName.avatar)
            getImage(userName.avatar)
        } catch (error) {
            
        }
    }

    const searchClientQuestions = async () => {
        console.log("paramas", id)
        setLoading(true)
        try {
            const [clientResult] = await Promise.all([
                ClientService.getClientQuestions(id),
                asyncTimeout(300)
            ])
            console.log("question results", clientResult)
            setQuestions(clientResult)
        } catch (error) {
            console.log("error", error)
        } finally {
            setLoading(false)
        }
    }

    const getImage = async (avatar) => {
        try {
          const imageUri = await ProfessionalService.downloadFile(avatar)
          setAvatar(imageUri)
        } catch (error) {
          console.log("error", error)
        }
      }

    useEffect(() => {
        loadInitialData()
    }, [])

    const loadInitialData = async () => {
        setLoading(true)
        await Promise.all([getUserName(), searchClientQuestions(), asyncTimeout(600)])
        setLoading(false)
    }

    const mapClientQuestions = () => {
        console.log("questions array", questions)
        if (questions.length === 0) {
            return (
            <div className='Title__Container'>
                <div>No Questions</div>
            </div>)
        }
        return questions.map((question, index) => {
            return <QuestionsCard key={index} question={question}/>
        })
    }

    return (
        <div className='Home__Main'>
            <SidePanel  currentPage={'Clients'}/>
            <div className='Home__Content'>
                <Header title={'Profile'} icon={ProfessionalsIcon}/>
                {loading ? <div class="loader"></div> :
                <>
                    <div style={{display: 'flex', flexDirection: 'row', marginBottom: 10, marginTop: 10, marginLeft: 45}}>
                        <img className='Profile__Image' src={avatar ? avatar :  EmptyAvatar} key={avatar}/>
                        <div className='Name_Title'>{user.firstName} {user.lastName}</div>
                    </div>
                    <div className='Details_Title'>Email:&nbsp; <div className='User_Data'> {user.email}</div></div>
                    <div className='Details_Title'>Mobile:&nbsp; <div className='User_Data'> {user.mobile}</div></div>
                    <div className='Details_Title'>Country of Origin:&nbsp; <div className='User_Data'> {user.countryOfOrigin !== '' ? user.countryOfOrigin : 'Not Specified'}</div></div>
                    <div className='Details_Title'>First Language:&nbsp; <div className='User_Data'>{user.firstLanguage}</div></div>
                    <div className='Details_Title'>Second Language:&nbsp; <div className='User_Data'>{user.secondLanguage ? user.secondLanguage : 'Not Specified'}</div></div>
                    <div>
                    <div className='Questions'>Questions: </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginLeft: 40}}>
                        {mapClientQuestions()}
                    </div>
                </>}
            </div>
        </div>
    )
}
