import React from 'react'

export default function File({ fileName, file }) {
    return (
        <a className='ProfileModal__File' href={file} target='_blank' >
            <div className='ProfileModal__File__Icon' />
            <div className='ProfileModal__Body'>{fileName}</div>
        </a>
    )
}
