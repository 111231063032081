import axios from 'axios'
import Instance from '../utils/Axios'

export const ProfessionalService = {
	getAllProfessionals: async (offset, limit, name, status) => {
		console.log('professionasdkasd data', offset, limit, name, status)
		try {
			const { data } = await Instance.post('', {
				query: `query GetProfessionals($name: String, $offset: Int, $limit: Int, $status: String) {
                  GetProfessionals(name: $name, offset: $offset, limit: $limit, status: $status) {
                    count
                    objectList {
                      id
                      firstName
                      email
                      lastName
                      MARANumber
                      prof_supportingdocuments {
                        id
                        fileName
                        key
                      }
                      LPNNumber
                      professionalspecialties {
                        specialty
                      }
                      mobile
                      avatar
                      status
                      lpn_documents {
                        id
                        fileName
                        key
                      }
                      lpn_expiry_date
                      MARAexpiryDate
                      MARA_documents {
                        key
                        fileName
                      }
                    }
                  }
                }`,
				variables: {
					offset,
					limit,
					name,
					status,
				},
			})
			console.log('getprofessionasdlasd as dapppi', data.data.GetProfessionals.objectList)
			if (!data.data.GetProfessionals) throw Error('Not Found')
			return data.data.GetProfessionals.objectList
		} catch (error) {
			console.log(error)
			if (axios.isAxiosError(error)) throw Error('Network')
			throw Error(error.message)
		}
	},

	declineProfessional: async (professionalId) => {
		try {
			const { data } = await Instance.post('', {
				query: `mutation ProfessionalBlock($professionalId: String) {
            ProfessionalSignupDecline(professionalId: $professionalId)
          }`,
				variables: {
					professionalId,
				},
			})
			if (!data.data) throw Error('Not Found')
		} catch (error) {
			console.log(error)
			if (axios.isAxiosError(error)) throw Error('Network')
			throw Error(error.message)
		}
	},

	approveProfessional: async (professionalId, professionalspecialties) => {
		try {
			const { data } = await Instance.post('', {
				query: `mutation ProfessionalSignupApprove($professionalId: String, $professionalspecialties: [ProfessionalSpecialtyEnumType]) {
            ProfessionalSignupApprove(professionalId: $professionalId, professionalspecialties: $professionalspecialties)
          }`,
				variables: {
					professionalId,
					professionalspecialties: professionalspecialties,
				},
			})

			if (!data.data) throw Error('Not Found')
		} catch (error) {
			console.log(error)
			if (axios.isAxiosError(error)) throw Error('Network')
			throw Error(error.message)
		}
	},

	updateProfessional: async (professionalspecialties, id) => {
		console.log('jasdjbansd', professionalspecialties, id)
		try {
			const { data } = await Instance.post('', {
				query: `mutation ProfessionalUpdate($professionalspecialties: [ProfessionalSpecialtyEnumType], $professional: ProfessionalInput) {
            ProfessionalUpdate(professionalspecialties: $professionalspecialties, professional: $professional)
          }`,
				variables: {
					professionalspecialties,
					professional: {
						id,
					},
				},
			})
			console.log('return data IJASDKJASHDJKA', data)
			if (!data.data) throw Error('Not Found')
		} catch (error) {
			console.log(error)
			if (axios.isAxiosError(error)) throw Error('Network')
			throw Error(error.message)
		}
	},

	downloadFile: async (filename) => {
		try {
			const { data } = await Instance.post('', {
				query: `query Root_query_type_v1($filename: String) {
            SingleDownload(filename: $filename)
          }`,
				variables: {
					filename,
				},
			})
			if (!data.data.SingleDownload) throw Error('Not Found')
			return data.data.SingleDownload
		} catch (error) {
			console.log(error)
			if (axios.isAxiosError(error)) throw Error('Network')
			throw Error(error.message)
		}
	},
}
