import axios from 'axios'
import Instance from '../utils/Axios'

export const ReportService = {
    getAllReports: async () => {
      console.log("getting reports")
        try {
            const { data } = await Instance.post('', {
                query: `query Root_query_type_v1 {
                  GetReports {
                    count
                    objectList {
                      id
                      topic
                      label
                      content
                      client {
                        firstName
                        lastName
                        email
                        mobile
                        id
                        avatarLink
                      }
                      question {
                        id
                        title
                        content
                        price
                        finishTime
                        destinationCountry
                        category
                        progress
                        professional {
                          professionalCode
                          firstName
                          lastName
                          email
                          mobile
                          MARANumber
                          professionalspecialties {
                            specialty
                          }
                          LPNNumber
                          avatarLink
                        }
                        questionreplies {
                          content
                          questionreplyfiles {
                            link
                          }
                          createdAt
                          stage
                        }
                        client {
                          firstName
                          lastName
                          email
                        }
                        questionfiles {
                          key
                          link
                        }
                      }
                      appointment {
                        id
                        title
                        context
                        link
                        price
                        timeStart
                        timeFinish
                        progress
                        cancelDetail
                        cancelTime
                        professional {
                          professionalCode
                          firstName
                          lastName
                          email
                          mobile
                          MARANumber
                          professionalspecialties {
                            specialty
                          }
                          LPNNumber
                          avatarLink
                        }
                      }
                    }
                  }
                }`,
            })
            console.log("report data", data.data.GetReports.objectList)
            if (!data.data.GetReports) throw Error('Not Found')
            return data.data.GetReports.objectList

        } catch (error) {
            console.log(error)
            if (axios.isAxiosError(error)) throw Error('Network')
            throw Error(error.message)
        }
    },

    sendEmail: async (email, text) => {
        try {
            const { data } = await Instance.post('', {
                query: `mutation ReportEmailReply($email: String, $text: String) {
                  ReportEmailReply(Email: $email, text: $text)
                }`,
                variables: {
                  email,
                  text
                }
            })
            console.log("email data", data.data.ReportEmailReply)
            if (!data.data.ReportEmailReply) throw Error('Not Found')
            return data.data.ReportEmailReply

        } catch (error) {
            console.log(error)
            if (axios.isAxiosError(error)) throw Error('Network')
            throw Error(error.message)
        }
    },

}