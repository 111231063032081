import React, { useState, useEffect } from 'react'
import Header from '../../components/Header/Header'
import SidePanel from '../../components/SidePanel/SidePanel'
import './Home.css'
import ProfessionalsIcon from '../../assets/Icons/Briefcase.png'
import ApplicationsIcon from '../../assets/Icons/Applications.png'
import Professionals from '../../components/Professionals/Professionals'
import { ProfessionalService } from '../../api/Professionals'
import asyncTimeout from '../../utils/asyncTimeout'

export default function Home() {

    const [professionals, setProfessionals] = useState([])
    const [loading, setLoading] = useState()

    const searchProfessionals = async (keyword) => {

        setLoading(true)

        try {
            const [professionalResult] = await Promise.all([
                ProfessionalService.getAllProfessionals(0, 10, keyword, 'active'),
                asyncTimeout(300)
            ])
            setProfessionals(professionalResult)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        searchProfessionals('')
    }, [])

    return (
        <div className='Home__Main'>
            <SidePanel  currentPage={'Professionals'}/>
            <div className='Home__Content'>
                <Header title={'Professionals'} icon={ProfessionalsIcon} searchProfessionals={searchProfessionals}/>
                <Professionals professionals={professionals} loading={loading} />
            </div>
        </div>
    )
}
