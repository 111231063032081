import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import SidePanel from '../../components/SidePanel/SidePanel'
import './ApplicationsPage.css'
import ApplicationsIcon from '../../assets/Icons/Applications.png'
import Applications from '../../components/Applications/Applications'
import { ProfessionalService } from '../../api/Professionals'
import asyncTimeout from '../../utils/asyncTimeout'

export default function ApplicationsPage() {

    const [professionals, setProfessionals] = useState([])
    const [loading, setLoading] = useState()
    const [currentTab, setCurrentTab] = useState('inactive')

    const searchProfessionals = async (keyword) => {
        setLoading(true)
        try {
            const [professionalResult] = await Promise.all([
                ProfessionalService.getAllProfessionals(0, 10, keyword, currentTab),
                asyncTimeout(300)
            ])
            setProfessionals(professionalResult)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        searchProfessionals('')
    }, [currentTab])

    return (
        <div className='ApplicationsPage__Main'>
            <SidePanel currentPage={'Applications'} />
            <div className='ApplicationsPage__Content'>
                <Header title={'Applications'} icon={ApplicationsIcon} searchProfessionals={searchProfessionals}/>
                <Applications professionals={professionals} loading={loading} currentTab={currentTab} setCurrentTab={setCurrentTab} searchProfessionals={searchProfessionals} />
            </div>
        </div>
    )
}
