import React, { useState, useEffect } from 'react'
import Profile from '../Profile/Profile'
import Report from '../Report/Report'
import './Reports.css'

export default function ProfileModal({ reports, loading }) {

    const renderReports = () => {
        if (loading) {
            return Array(6).fill(0).map(() => (
                <Profile loading={loading} />
            ))
        } else {
            return reports.map((report, index) => (
                <Report key={index} data={report} />
            ))
        }
    }

    console.log(reports)

    return (
        <div className='Reports__Main'>
            <div className='Reports__Container'>
                {renderReports()}
            </div>
        </div>
    )
}
