import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { AuthService } from '../../api/Auth'
import TFASnackBar from '../../components/TFASnackBar/TFASnackBar'
import './Authentication.css'

export default function Authentication() {

    const [user, setUser] = useState()
    const [password, setPassword] = useState()
    const [loading, setLoading] = useState()

    const history = useHistory()
    const token = localStorage.getItem('TFAAdminToken')
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false)

    const adminSignIn = async (e) => {
        e.preventDefault()
        setLoading(true)

        try {
            const signInResult = await AuthService.signInAdmin(user, password)
            history.push({ pathname: '/Professionals' })
            console.log('Signin successful: ', localStorage.getItem('TFAAdminToken'))

        } catch (error) {
            console.log(error)
            setIsSnackbarOpen(true)
        }
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return
        setIsSnackbarOpen(false)
    }

    return (
        <div className='Authentication__Main'>
            <form className='Authentication__Container' onSubmit={(e) => adminSignIn(e)}>
                <div className='TFA__Logo' />
                <div className='Auth__Title'>Admin Panel Access</div>
                <input className={null} placeholder='Username' type='text' onChange={(e) => setUser(e.target.value)} />
                <input className={null} placeholder='Password' type='password' onChange={(e) => setPassword(e.target.value)} />
                <button className='BlueButton__Full' onClick={adminSignIn} type='submit'>Sign In</button>
            </form>
            <TFASnackBar
                variant='error'
                isSnackbarOpen={isSnackbarOpen}
                handleSnackbarClose={handleSnackbarClose}
                message='Failed to login. Please try again.'
            />

            <div className='MobileMessage__Main'>
                <div className='TFA__Logo' />
                <div>This platform isn't viewable from mobile, try viewing on a tablet or computer.</div>
            </div>

        </div>
    )
}