import React, { useState, useEffect, FunctionComponent } from 'react'
import Profile from '../Profile/Profile'
import './Clients.css'
import profileImage from '../../assets/Images/Profile.jpg'
import { Client } from '../../Types/types'
import { ProfessionalService } from '../../api/Professionals'
import EmptyAvatar from '../../assets/Icons/EmptyAvatar.svg'
import { Link } from 'react-router-dom'

type Props = {
    clients: Client[]
    loading: boolean
}

const Clients: FunctionComponent<Props> = ({ clients, loading }) => {
    const [avatars, setAvatars] = useState<string[]>([])

    const getImages = async () => {
        console.log("data avatar pls", clients)

        try {
            const data = await Promise.all(clients.map(async(client) => {
                if (client.avatar !== ''){
                return await ProfessionalService.downloadFile(client.avatar)
                }
                else {
                    return ''
                }
            }))
            console.log("propper?", data)
            setAvatars([...data])
        } catch (error) {
            console.log("error", error)
        }
      }

    useEffect(() => {
        getImages()
    },[clients])

    const renderHeaders = () => {
        return (
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div className='Professionals_Details' >
                    <h4 style={{textOverflow: 'ellipsis', textAlign: 'center'}}>Full Name</h4>
                </div>
                <div className='Professionals_Details'>
                    <h4 style={{textOverflow: 'ellipsis', textAlign: 'center'}}>Email</h4>
                </div>
                <div className='Professionals_Details' style={{width: 150, maxWidth: 150}}>
                    <h4 style={{textOverflow: 'ellipsis', textAlign: 'center'}}>Mobile</h4>
                </div>
                <div className='Professionals_Details' style={{width: 150, maxWidth: 150}}>
                    <h4 style={{textOverflow: 'ellipsis', textAlign: 'center'}}>First Language</h4>
                </div>
                <div className='Professionals_Details' style={{width: 150, maxWidth: 150, borderRightWidth: 1}}>
                    <h4 style={{textOverflow: 'ellipsis', textAlign: 'center'}}>Country Of Origin</h4>
                </div>
            </div>)
    }

    const mapClients = () => {
        return clients.map((client, index) => {
            return (
                <div style={{display: 'flex', flexDirection: 'row', backgroundColor: index % 2 == 0 ? '#8aafeb' : 'white'}}>
                    <div className='Professionals_Details' style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Link to={`/ClientProfile/${client.id}`}>
                        <img onClick={() => console.log("gammin")} className='Profile__Image' style={{marginTop: 10, marginBottom: 10, marginRight: 10}} src={avatars[index] !== '' ? avatars[index] : EmptyAvatar}/>
                    </Link>
                        <h4 style={{textOverflow: 'ellipsis'}}>{client.firstName} {client.lastName}</h4>
                    </div>
                    <div className='Professionals_Details'>
                        <h4 style={{textOverflow: 'ellipsis'}}>{client.email}</h4>
                    </div>
                    <div className='Professionals_Details' style={{width: 150, maxWidth: 150}}>
                        <h4 style={{textOverflow: 'ellipsis'}}>{client.mobile}</h4>
                    </div>
                    <div className='Professionals_Details' style={{width: 150, maxWidth: 150}}>
                        <h4 style={{textOverflow: 'ellipsis'}}>{client.firstLanguage}</h4>
                    </div>
                    <div className='Professionals_Details' style={{width: 150, maxWidth: 150, borderRightWidth: 1}}>
                        <h4 style={{textOverflow: 'ellipsis'}}>{client.countryOfOrigin}</h4>
                    </div>
                </div>)
        })
    }

    return (
        <div className='Professionals__Main'>
            <div className='Professionals__Container1'>
                {renderHeaders()}
                {mapClients()}
            </div>
        </div>
    )
}

export default Clients
