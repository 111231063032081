import React, { useState } from 'react'
import ApplicationModal from '../ApplicationModal/ApplicationModal'
import ApplicationResultModal from '../ApplicationResultModal/ApplicationResultModal'
import ProfileModal from '../ProfileModal/ProfileModal.tsx'
import TFASkeleton from '../TFASkeleton/TFASkeleton'
import './Report.css'
import EmptyAvatar from '../../assets/Icons/EmptyAvatar.svg'
import ReportModal from '../ReportModal/ReportModal'

export default function Report({ image, data, loading, searchProfessionals }) {

  const [openReport, setOpenReport] = useState(false)
  const [openReportDetails, setOpenReportDetails] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const renderModal = () => {
    switch (data.status) {
      case 'active': return
    }
  }

  return (
    <div className='Report__Main' >

      {data
        ? <>
          <TFASkeleton width={64} height={64} loading={loading} variant='circular'>
            <img className='Reports__Image' src={data.client.avatarLink !== 'false' ? data.client.avatarLink : EmptyAvatar} />
          </TFASkeleton>
          <TFASkeleton width={'80%'} height={40} loading={loading} variant='text'>
            <div className='Reports__Name'>{data.client.firstName + ' ' + data.client.lastName}</div>
            <button className='Reports__ViewButton' onClick={() => setOpenReport(true)} />
          </TFASkeleton>

          <ReportModal open={openReport} setOpen={setOpenReport} emailSent={() => setEmailSent(true)} data={data} />
          
        </>

        : <>
          <TFASkeleton width={64} height={64} loading={loading} variant='circular'>
            <div className='Reports__Image' />
          </TFASkeleton>
          <TFASkeleton width={'80%'} height={40} loading={loading} variant='text'>
            <div className='Reports__Name' />
            <button className='Reports__ViewButton' />
          </TFASkeleton>
        </>}
    </div>
  )
}