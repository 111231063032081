import React, { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import SidePanel from '../../components/SidePanel/SidePanel'
import './ReportsPage.css'
import ReportsIcon from '../../assets/Icons/Flag.png'
import Reports from '../../components/Reports/Reports'
import { ProfessionalService } from '../../api/Professionals'
import asyncTimeout from '../../utils/asyncTimeout'
import { ReportService } from '../../api/Reports'

export default function ReportsPage() {

    const [reports, setReports] = useState([])
    const [loading, setLoading] = useState()
    const [currentTab, setCurrentTab] = useState('inactive')

    const getReports = async () => {
        setLoading(true)
        try {
            const [reportResult] = await Promise.all([
                ReportService.getAllReports(),
                asyncTimeout(300)
            ])
            setReports(reportResult)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
      getReports()
    }, [])
    

    return (
        <div className='ReportsPage__Main' >
            <SidePanel currentPage={'Reports'}/>
            <div className='ReportsPage__Content'>
                <Header title={'Reports'} icon={ReportsIcon} searchProfessionals={''} />
                <Reports reports={reports} loading={loading}/>
            </div>
        </div>
    )
}
