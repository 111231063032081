import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import ModalWrapper from '../ModalWrapper/ModalWrapper'
import './ConfirmSignOut.css'
import { AuthService } from '../../api/Auth'

export default function ConfirmSignOut({ open, setOpen }) {

    const history = useHistory()

    const authSignout = async () => {
        try {
            const signOutResult = await AuthService.signOutAdmin()
            localStorage.removeItem('TFAAdminToken')
            history.push({ pathname: '/' })
            console.log('Signout successful: ', localStorage.getItem('TFAAdminToken'))
        } catch (error) {

        }
    }

    return (
        <ModalWrapper open={open} setOpen={setOpen} width='440px'>

            <div className='SignoutModal__Main'>
                <div className='SignoutModal__Title'>Confirm Signout</div>

                <div className='Button__Container' >
                    <div className='BlueButton__Double' onClick={() => setOpen(false)}>Cancel</div>
                    <div className='RedButton__Double' onClick={authSignout}>SignOut</div>
                </div>
            </div>

        </ ModalWrapper>
    )
}