import { Skeleton } from '@mui/material'
import React from 'react'

export default function TFASkeleton({ height, width, loading, variant, sx, children, count }) {
    if (loading && count) {
        return Array(count).fill(0).map(() => {
            return (
                <Skeleton variant={variant} width={width} height={height} sx={sx}>
                    {children}
                </Skeleton>
            )
        })
    }

    if (loading) {
        return (
            <Skeleton variant={variant} width={width} height={height} sx={sx}>
                {children}
            </Skeleton>
        )
    } else return children
}
