import React, { useState, useEffect } from 'react'
import './Header.css'

export default function Header({ title, icon, searchProfessionals }) {

    const [keyword, setKeyword] = useState('')

    useEffect(() => {

        if (!searchProfessionals) {
            
        } else {
            const timer = setTimeout(() => {
                searchProfessionals(keyword)
            }, 300)
            return () => clearTimeout(timer)
        }

    }, [keyword])

    return (
        <div className='Header__Main'>
            <div className='Header__Title'>
                <div className='Header__Title__Icon' style={{ backgroundImage: `url('${icon}')` }} />
                <div className='Header__Title__Text' >{title}</div>
            </div>
            {!searchProfessionals ? null :
                <div className='Header__Search'>
                    <input className='Header__Search__Input' placeholder='Search' onChange={(e) => setKeyword(e.target.value)} />
                    <div className='Header__Search__Icon' />
                </div>}
        </div>
    )
}
