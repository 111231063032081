export const specialties = [
	'Visiting Visas',
	'Family Visas',
	'Skilled Visas',
	'Employer Visas',
	'Business Visas',
	'Tourist Visas',
	'Other Personal Visas',
	'Humanitarian Visas',
	'Bridging Visas',
	'Student Visas',
]
