import { useEffect, useState } from 'react'
import ModalWrapper from '../ModalWrapper/ModalWrapper'
import './ApplicationModal.css'
import File from '../File/File'
import { ProfessionalService } from '../../api/Professionals'
import 'react-dropdown/style.css'
import { useFormik } from 'formik'
import { InitialProSignupValues } from '../../assets/Data/FormikInitialValues'
import SelectBox from '../SelectBox/SelectBox'
import moment from 'moment'
import { specialties } from '../../assets/Data/SelectOptions'
import { specialtyConverter } from '../../utils/specialtyConverter'

export default function ApplicationModal({ open, setOpen, setApproved, setOpenResult, data, searchProfessionals }) {
	const [supportingDocs, setSupportingDocs] = useState([])
	const [maraDocs, setMaraDocs] = useState([])
	const [lpnDocs, setLpnDocs] = useState([])
	const [specialtySet, setSpecialtySet] = useState(false)

	const updateProfessional = async (action, specialtiesList) => {
		try {
			if (action) {
				if (specialtiesList.length === 0) {
					setSpecialtySet(true)
					return
				}
				await ProfessionalService.approveProfessional(data.id, specialtiesList)
				searchProfessionals()
				setOpen(false)
				setOpenResult(true)
				setApproved(true)
			} else {
				ProfessionalService.declineProfessional(data.id)
				searchProfessionals()
				setOpen(false)
				setOpenResult(true)
				setApproved(false)
			}
		} catch (error) {
			console.log(error)
		}
	}

	const formik = useFormik({
		initialValues: InitialProSignupValues,
		onSubmit: async (values) => {
			const formattedSpecialties = values.specialty.map((specialty) => specialtyConverter(specialty))
			updateProfessional(true, formattedSpecialties)
		},
	})

	const downloadSupportingDocs = async () => {
		try {
			let documentDownloadPromises = []

			data.prof_supportingdocuments.map((document, index) => {
				documentDownloadPromises.push(ProfessionalService.downloadFile(document.key))
			})

			const documentDownloadResult = await Promise.all(documentDownloadPromises)

			setSupportingDocs(documentDownloadResult)
		} catch (error) {
			console.log(error)
		}
	}

	const downloadMaraDocs = async () => {
		try {
			const docData = await Promise.all(
				data.MARA_documents.map(async (file) => {
					return await ProfessionalService.downloadFile(file.key)
				})
			)
			setMaraDocs(docData)
		} catch (error) {
			console.log(error)
		}
	}

	const downloadLPNDocs = async () => {
		try {
			const docData = await Promise.all(
				data.lpn_documents.map(async (file) => {
					return await ProfessionalService.downloadFile(file.key)
				})
			)
			setLpnDocs(docData)
		} catch (error) {
			console.log(error)
		}
	}

	const renderSupportingDocs = () => {
		if (data.prof_supportingdocuments.length === 0) return <div className='noProfessionals'>No Files to Show</div>

		return supportingDocs.map((document, index) => (
			<File key={index} fileName={data.prof_supportingdocuments[index].fileName} file={document} />
		))
	}

	const renderMaraDocs = () => {
		if (data.MARA_documents.length === 0) return <div className='noProfessionals'>No Files to Show</div>
		return maraDocs.map((document, index) => <File key={index} fileName={data.MARA_documents[index].fileName} file={document} />)
	}

	const renderLPNDocs = () => {
		if (data.lpn_documents.length === 0) return <div className='noProfessionals'>No Files to Show</div>
		return lpnDocs.map((document, index) => <File key={index} fileName={data.lpn_documents[index].fileName} file={document} />)
	}

	const mapProfessionalSpecialties = () => {
		return data.professionalspecialties.map((specialty, index) => {
			return (
				<div className='ProfileModal__Body' key={index}>
					{specialty.specialty}
				</div>
			)
		})
	}

	useEffect(() => {
		downloadSupportingDocs()
		downloadMaraDocs()
		downloadLPNDocs()
	}, [])

	const renderCertificateDetails = () => {
		if (data.MARANumber)
			return (
				<>
					<div className='ProfileModal__InfoBlock'>
						<div className='ProfileModal__SubHeading'>MARA Number</div>
						<div className='ProfileModal__Body'>{data.MARANumber}</div>
						<div className='ProfileModal__SubHeading'>MARA expiry</div>
						<div className='ProfileModal__Body'>{moment(data.MARAexpiryDate, 'YYYY-MM-DD').format('DD-MM-YYYY')}</div>
						<div className='Valid_Text' style={{ color: moment(data.MARAexpiryDate, 'YYYY-MM-DD').isAfter(moment()) ? 'forestgreen' : 'Red' }}>
							{moment(data.MARAexpiryDate, 'YYYY-MM-DD').isAfter(moment()) ? 'Valid' : 'Invalid'}
						</div>
						<div className='ProfileModal__SubHeading'>MARA Documents</div>
						{renderMaraDocs()}
					</div>
				</>
			)
		else if (data.LPNNumber)
			return (
				<div className='ProfileModal__InfoBlock'>
					<div className='ProfileModal__SubHeading'>LPN Number</div>
					<div className='ProfileModal__Body'>{data.LPNNumber}</div>
					<div className='ProfileModal__SubHeading'>LPN expiry</div>
					<div className='ProfileModal__Body'>{moment(data.lpn_expiry_date, 'YYYY-MM-DD').format('DD-MM-YYYY')}</div>
					<div className='Valid_Text' style={{ color: moment(data.lpn_expiry_date, 'YYYY-MM-DD').isAfter(moment()) ? 'forestgreen' : 'Red' }}>
						{moment(data.lpn_expiry_date, 'YYYY-MM-DD').isAfter(moment()) ? 'Valid' : 'Invalid'}
					</div>
					<div className='ProfileModal__SubHeading'>LPN Documents</div>
					{renderLPNDocs()}
				</div>
			)
	}

	return (
		<ModalWrapper open={open} setOpen={setOpen} width='60%'>
			<div className='ProfileModal__Main'>
				<div className='ProfileModal__Profile'>
					<div className='ProfileModal__Text'>Professional Application Submission</div>
				</div>

				<div className='ProfileModal__InfoBlocks'>
					<div className='ProfileModal__InfoBlock'>
						<div className='ProfileModal__SubHeading'>Professional Details</div>
						<div className='ProfileModal__Body'>{data.firstName + ' ' + data.lastName}</div>
						<div className='ProfileModal__Body'>{data.email}</div>
						<div className='ProfileModal__Body'>{data.mobile}</div>
					</div>

					<div className='ProfileModal__InfoBlock'>
						<div className='ProfileModal__SubHeading'>Specialty</div>
						{mapProfessionalSpecialties()}
					</div>

					{data.status === 'inactive' && (
						<div className='ProfileModal__InfoBlock'>
							<div className='ProfileModal__SubHeading'>Set Specialty</div>
							<SelectBox width='100%' selectArray={specialties} label='SPECIALTY' name='specialty' formik={formik} multiple={true} />
						</div>
					)}

					{specialtySet && (
						<div className='ProfileModal__Body' style={{ color: 'red' }}>
							User must have at least 1 specialty.
						</div>
					)}

					{renderCertificateDetails()}

					<div className='ProfileModal__InfoBlock'>
						<div className='ProfileModal__SubHeading'>Supporting Documents</div>
						{renderSupportingDocs()}
					</div>
				</div>

				{data.status === 'inactive' ? (
					<>
						<button className='GreenButton__Full' onClick={formik.handleSubmit}>
							Accept
						</button>
						<button
							className='RedButton__Full'
							onClick={() => {
								updateProfessional(false)
							}}
						>
							Decline
						</button>
					</>
				) : (
					<div className='SmallText'>Professional has already been approved but has not completed their account.</div>
				)}
			</div>
		</ModalWrapper>
	)
}
