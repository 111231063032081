import { useState, useEffect, FunctionComponent } from 'react'
import File from '../File/File'
import ModalWrapper from '../ModalWrapper/ModalWrapper'
import './ProfileModal.css'
import { ProfessionalService } from '../../api/Professionals'
import EmptyAvatar from '../../assets/Icons/EmptyAvatar.svg'
import { Professional } from '../../Types/types'
import SelectBox from '../SelectBox/SelectBox'
import { useFormik } from 'formik'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { specialties } from '../../assets/Data/SelectOptions'
import { specialtyConverter } from '../../utils/specialtyConverter'

type Props = {
	open: boolean
	setOpen: (isOpen: boolean) => void
	professional: Professional
}

const ProfileModal: FunctionComponent<Props> = ({ open, setOpen, professional }) => {
	const [supportingDocs, setSupportingDocs] = useState<string[]>([])
	const [maraDocs, setMaraDocs] = useState<string[]>([])

	const history = useHistory()

	const currentProfessionalSpecialties = professional.professionalspecialties.map((specialty) => specialty.specialty)

	const downloadSupportingDocs = async () => {
		try {
			let documentDownloadPromises: any[] = []
			professional.prof_supportingdocuments.map((document) => {
				documentDownloadPromises.push(ProfessionalService.downloadFile(document.key))
			})
			const documentDownloadResult = await Promise.all(documentDownloadPromises)
			setSupportingDocs(documentDownloadResult)
		} catch (error) {
			console.log(error)
		}
	}

	const downloadMaraDocs = async () => {
		try {
			const docData = await Promise.all(
				professional.MARA_documents.map(async (file) => {
					return await ProfessionalService.downloadFile(file.key)
				})
			)
			setMaraDocs(docData)
		} catch (error) {
			console.log(error)
		}
	}

	const renderSupportingDocs = () => {
		if (professional.prof_supportingdocuments.length === 0) return <div className='noProfessionals'>No Files to Show</div>

		return supportingDocs.map((document, index) => (
			<File key={index} fileName={professional.prof_supportingdocuments[index].fileName} file={document} />
		))
	}

	const renderMaraDocs = () => {
		if (professional.MARA_documents.length === 0) return <div className='noProfessionals'>No Files to Show</div>
		return maraDocs.map((document, index) => <File key={index} fileName={professional.MARA_documents[index].fileName} file={document} />)
	}

	const formik = useFormik({
		initialValues: {
			specialties: currentProfessionalSpecialties,
		},
		onSubmit: async (values) => {
			const formattedSpecialties = values.specialties.map((specialty) => specialtyConverter(specialty))
			try {
				await ProfessionalService.updateProfessional(formattedSpecialties, professional.id)
				history.go(0)
				setOpen(false)
			} catch (error) {
				console.log('error', error)
			}
		},
	})

	const mapSpecialties = () => {
		return professional.professionalspecialties.map((specialty, index) => {
			return (
				<div className='ProfileModal__Body' key={index}>
					{specialty.specialty}
				</div>
			)
		})
	}

	useEffect(() => {
		downloadSupportingDocs()
		downloadMaraDocs()
	}, [professional])

	return (
		<ModalWrapper open={open} setOpen={setOpen} width='40%' minWidth={undefined} maxWidth={undefined}>
			<div className='ProfileModal__Main'>
				<div className='ProfileModal__Profile'>
					<img className='ProfileModal__Image' src={professional.avatarLink !== 'false' ? professional.avatarLink : EmptyAvatar} />
					<div className='ProfileModal__Text'>{professional.firstName + ' ' + professional.lastName}</div>
				</div>

				<div className='ProfileModal__InfoBlocks'>
					<div className='ProfileModal__InfoBlock'>
						<div className='ProfileModal__SubHeading'>Contact Information</div>
						<div className='ProfileModal__Body'>{professional.email}</div>
						<div className='ProfileModal__Body'>{professional.mobile}</div>
					</div>

					<div className='ProfileModal__InfoBlock'>
						<div className='ProfileModal__SubHeading'>Specialty</div>
						{mapSpecialties()}
					</div>
					<div className='ProfileModal__InfoBlock'>
						<div className='ProfileModal__SubHeading'>Edit Specialties</div>
					</div>
					<div className='ProfessionalUpdateText'>To update a Professional's specialties, please select ALL applicable options</div>
					<SelectBox width='100%' selectArray={specialties} label='SPECIALTY' name='specialties' formik={formik} multiple={true} />

					{professional.MARANumber.length !== 0 ? (
						<div className='ProfileModal__InfoBlock'>
							<div className='ProfileModal__SubHeading'>MARA Number</div>
							<div className='ProfileModal__Body'>{professional.MARANumber}</div>
							<div className='ProfileModal__SubHeading'>MARA expiry</div>
							<div className='ProfileModal__Body'>{moment(professional.MARAexpiryDate, 'YYYY-MM-DD').format('DD-MM-YYYY')}</div>
							<div
								className='Valid_Text'
								style={{ color: moment(professional.MARAexpiryDate, 'YYYY-MM-DD').isAfter(moment()) ? 'forestgreen' : 'Red' }}
							>
								{moment(professional.MARAexpiryDate, 'YYYY-MM-DD').isAfter(moment()) ? 'Valid' : 'Invalid'}
							</div>
							<div className='ProfileModal__SubHeading'>MARA Documents</div>
							{renderMaraDocs()}
						</div>
					) : (
						<div className='ProfileModal__InfoBlock'>
							<div className='ProfileModal__SubHeading'>LPN Number</div>
							<div className='ProfileModal__Body'>{professional.LPNNumber}</div>
						</div>
					)}
					<div className='ProfileModal__InfoBlock'>
						<div className='ProfileModal__SubHeading'>Supporting Documents</div>
						{renderSupportingDocs()}
					</div>
				</div>

				{formik.values.specialties !== currentProfessionalSpecialties ? (
					<button type='submit' className='GreenButton__Full' onClick={() => formik.handleSubmit()}>
						SAVE
					</button>
				) : (
					<button className='BlueButton__Full' onClick={() => setOpen(false)}>
						OKAY
					</button>
				)}
			</div>
		</ModalWrapper>
	)
}

export default ProfileModal
