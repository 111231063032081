import React, { FunctionComponent } from 'react'
import {
    OutlinedInput,
    MenuItem,
    Select,
    Alert
} from '@mui/material'
import { TFAFormControl, TFAInputLabel } from './MuiStyle'
import { SelectMenuProps } from '../../utils/GlobalMuiStyle'

type Props = {
    formik: any 
    name: string
    required?: any
    label: string
    selectArray: string[]
    reportArray?: any[]
    error?: any
    multiple?: any
    textAlign?: any
    width: string
}
const TFASelectBox: FunctionComponent<Props> = ({ 
    formik, 
    name,
    required,
    label,
    selectArray,
    reportArray,
    error,
    multiple,
    textAlign,
    width,
}) => {
    return (
        <div style={{textAlign: textAlign ?? ''}}>
            <TFAFormControl 
                sx={{mt: '0.3em', width: width ?? '28em', maxWidth: '100%'}}
                required={required}
            >
                <TFAInputLabel>
                    {label}
                </TFAInputLabel>
                
                <Select
                    name={name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values[name] ?? ''}
                    MenuProps={SelectMenuProps}
                    input={<OutlinedInput label={label} />}
                    multiple={multiple ?? false}
                >
                    {selectArray &&
                    selectArray.map(option => (
                    <MenuItem
                        key={option}
                        value={option}
                        sx={{'&.MuiButtonBase-root': {
                            fontFamily: "'Space Grotesk', sans-serif",
                        }}}
                    >
                        {option}
                    </MenuItem>
                    ))}
                    {reportArray &&
                    reportArray.map(option => (
                    <MenuItem
                        key={option.id}
                        value={option.id}
                        sx={{'&.MuiButtonBase-root': {
                            fontFamily: "'Space Grotesk', sans-serif",
                        }}}
                    >
                        {option.title}
                    </MenuItem>
                    ))}
                </Select>

                {formik?.touched[name] && error && 
                    <Alert 
                        severity='error' 
                        sx={{padding: '0 1em', marginTop: '0.2em'}}
                    >
                        {error}
                    </Alert>
                }
            </TFAFormControl>
        </div>
    )
}

export default TFASelectBox