import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import File from '../File/File'
import ModalWrapper from '../ModalWrapper/ModalWrapper'
import './ReportModal.css'
import EmptyAvatar from '../../assets/Icons/EmptyAvatar.svg'
import EmailComp from '../EmailComp/EmailComp'

export default function ReportModal({ open, setOpen, data, emailSent }) {
    const [emailClient, setEmailClient] = useState(false)
    const [emailProfessional, setEmailProfessional] = useState(false)

    const alterReplyTitle = (string) => {
        if (string.includes('professional')) return string.replace('professionalReply', 'Professional Reply ')
        if (string.includes('client')) return string.replace('clientReply', 'Client Reply ')
    }
    console.log("profesisonal data", data.question?.professional?.email)
    const renderDetails = () => {
        if (data.question) return renderQuestionDetails()
        if (data.appointment) return renderAppointmentDetails()
    }

    const valueToPrice = (price) => {
        const stringPrice = String(price)
          return '$' + stringPrice.substring(0, stringPrice.length-2) + '.' + stringPrice.substring(stringPrice.length-2, stringPrice.length);  
    }

    const renderQuestionDetails = () => (
        <>
            <div className='ReportModal__Question'>
                <div className='Question__Container'>
                    <div className='Question'>Question: </div>
                    <div className='Question__Price'>{valueToPrice(data.question.price) / 100}</div>
                </div>
                <div className='Question__Content'>{data.question.content}</div>
                <div className={`Question__Status__${data.question.progress}`} >{data.question.progress}</div>
            </div>

            {data.question.professional &&
                <div className='ReportModal__ProfessionalContent'>
                    <div className='ReportModal__Response'>
                        <div className='ReportModal__SubHeading'>Response from Professional</div>
                        <div className='Response__Container'>
                            {data.question.questionreplies.map((reply, index) => (
                                <div className='Response__Content'>
                                    <div key={index} className='ReportModal__ReplyTitle'>{alterReplyTitle(reply.stage)}</div>
                                    <div key={index} className='ReportModal__Body'>{reply.content}</div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='ReportModal__Professional'>
                        <div className='ReportModal__SubHeading'>Reported Professional</div>
                        <div className='Professional__Profile'>
                            <img className='ReportProfessional__Image' src={data.question.professional.avatarLink ?? EmptyAvatar} />
                            <div>
                                <div className='Profile__Name'>{data.question.professional.firstName + ' ' + data.question.professional.lastName}</div>
                                <div className='ReportModal__Body'>{data.question.professional.email}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )

    const renderAppointmentDetails = () => (
        <>
            <div className='ReportModal__Question'>
                <div className='Question__Container'>
                    <div className='Question'>Appointment: </div>
                    <div className='Question__Price'>${data.appointment.price}</div>
                </div>
                <div className='Question__Content'>{data.appointment.content}</div>
                <div className={`Question__Status__${data.appointment.progress}`} >{data.appointment.progress}</div>
            </div>

            {data.appointment.professional &&
                <div className='ReportModal__ProfessionalContent'>
                    <div className='ReportModal__Response'>
                        <div className='ReportModal__SubHeading'>Response from Professional</div>
                        <div className='Response__Container'>
                            <div className='ReportModal__Body'>{data.appointment.cancelDetail}</div>
                        </div>
                    </div>

                    <div className='ReportModal__Professional'>
                        <div className='ReportModal__SubHeading'>Reported Professional</div>
                        <div className='Professional__Profile'>
                            <img className='ReportProfessional__Image' src={data.appointment.professional.avatarLink ?? EmptyAvatar} />
                            <div>
                                <div className='Profile__Name'>{data.appointment.professional.firstName + ' ' + data.appointment.professional.lastName}</div>
                                <div className='ReportModal__Body'>{data.appointment.professional.email}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )

    return (
        <ModalWrapper open={open} setOpen={setOpen} width='440px'>
            <div className='ReportModal__Main'>

                <div className='ReportModal__Profile'>
                    <img className='ReportModal__Image' src={data.client.avatarLink !== 'false' ? data.client.avatarLink : EmptyAvatar} />
                    <div className='ReportModal__Text' >{data.client.firstName + ' ' + data.client.lastName}</div>
                </div>

                <div className='ReportModal__InfoBlocks'>

                    <div className='ReportModal__InfoBlock'>
                        <div className='ReportModal__SubHeading'>Report Reason</div>
                        <div className='ReportModal__Body'>{data.topic}</div>
                    </div>

                    <div className='ReportModal__InfoBlock'>
                        <div className='ReportModal__SubHeading'>Report Details</div>
                        <div className='ReportModal__Body'>{data.content}</div>
                    </div>
                </div>

                {renderDetails()}

                {emailClient && <EmailComp onSend={() => [emailSent(), setOpen(false)]} receiver={data.client.email}/>}
                {emailProfessional && <EmailComp onSend={() => [emailSent(), setOpen(false)]} receiver={data.question?.professional?.email ? data.question?.professional?.email : data.appointment?.professional?.email}/>}

                {!emailClient && !emailProfessional &&
                <> 
                    {/* <button className='BlueButton__Full' onClick={() => setEmailClient(true)}>Email Client</button>
                    <button className='BlueButton__Full' onClick={() => setEmailProfessional(true)}>Email Professional</button> */}
                    <button className='BlueButton__Full' onClick={() => setOpen(false)}>CLOSE</button>
                </>}

            </div>
        </ ModalWrapper>
    )
}
