import React, { useState, useEffect } from 'react'
import Header from '../../components/Header/Header'
import SidePanel from '../../components/SidePanel/SidePanel'
import './ClientsPage.css'
import ProfessionalsIcon from '../../assets/Icons/Briefcase.png'
import asyncTimeout from '../../utils/asyncTimeout'
import { ClientService } from '../../api/Client'
import Clients from '../../components/Clients/Clients.tsx'

export default function ClientsPage() {

    const [clients, setClients] = useState([])
    const [loading, setLoading] = useState()

    const searchClients = async (keyword) => {

        setLoading(true)

        try {
            const [clientResult] = await Promise.all([ClientService.getClients(keyword), asyncTimeout(300)])
            console.log("client results data", clientResult)
            setClients(clientResult)
        } catch (error) {
            console.log("error", error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        searchClients('')
    }, [])

    return (
        <div className='Home__Main'>
            <SidePanel  currentPage={'Clients'}/>
            <div className='Home__Content'>
                <Header title={'Clients'} icon={ProfessionalsIcon} searchProfessionals={searchClients}/>
                <Clients clients={clients} loading={loading} />
            </div>
        </div>
    )
}
