import { useEffect, useState } from 'react'
import ApplicationModal from '../ApplicationModal/ApplicationModal'
import ApplicationResultModal from '../ApplicationResultModal/ApplicationResultModal'
import ProfileModal from '../ProfileModal/ProfileModal.tsx'
import TFASkeleton from '../TFASkeleton/TFASkeleton'
import './Profile.css'
import EmptyAvatar from '../../assets/Icons/EmptyAvatar.svg'
import { ProfessionalService } from '../../api/Professionals'
import { Link } from 'react-router-dom'

export default function Profile({ data, loading, searchProfessionals, client }) {
	const [openProfile, setOpenProfile] = useState(false)
	const [openApplication, setOpenApplication] = useState(false)
	const [openResult, setOpenResult] = useState(false)
	const [approved, setApproved] = useState(true)
	const [avatar, setAvatar] = useState('')

	const getImage = async () => {
		if (!data?.avatar) return

		try {
			const imageUri = await ProfessionalService.downloadFile(data.avatar)
			setAvatar(imageUri)
		} catch (error) {
			console.log('error', error)
		}
	}

	useEffect(() => {
		getImage()
	}, [])

	return (
		<div className='Profile__Main'>
			{data ? (
				<>
					<TFASkeleton width={64} height={64} loading={loading} variant='circular'>
						<img className='Profile__Image' src={avatar ? avatar : EmptyAvatar} />
					</TFASkeleton>
					<TFASkeleton width={'80%'} height={40} loading={loading} variant='text'>
						<div className='Profile__Name'>{data.firstName + ' ' + data.lastName}</div>

						{!client && (
							<button
								className='Profile__ViewButton'
								onClick={data.status === 'active' ? () => setOpenProfile(true) : () => setOpenApplication(true)}
							/>
						)}
						{client && (
							<Link style={{ marginLeft: 'auto' }} to={`/ClientProfile/${client}`}>
								<button
									className='Profile__ViewButton'
									onClick={data.status === 'active' ? () => setOpenProfile(true) : () => setOpenApplication(true)}
								/>
							</Link>
						)}
					</TFASkeleton>

					{!client && (
						<ApplicationModal
							open={openApplication}
							setOpen={setOpenApplication}
							openResult={openResult}
							setOpenResult={setOpenResult}
							setApproved={setApproved}
							data={data}
							searchProfessionals={searchProfessionals}
						/>
					)}
					{!client && <ApplicationResultModal open={openResult} setOpen={setOpenResult} approved={approved} />}
					{!client && data && <ProfileModal open={openProfile} setOpen={setOpenProfile} professional={data} />}
				</>
			) : (
				<>
					<TFASkeleton width={64} height={64} loading={loading} variant='circular'>
						<div className='Profile__Image' />
					</TFASkeleton>
					<TFASkeleton width={'80%'} height={40} loading={loading} variant='text'>
						<div className='Profile__Name' />
						<button className='Profile__ViewButton' />
					</TFASkeleton>
				</>
			)}
		</div>
	)
}
