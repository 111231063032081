import { Link } from 'react-router-dom'
import React from 'react'
import './PanelOption.css'

export default function PanelOption({ title, icon, option, link, setSignout, isButton, currentPage }) {
    return (
        <div className='PanelOption__Main'>
            <div className='PanelOption__Title'>{title}</div>
            {isButton ?
             <div className={currentPage ? 'PanelOption__Button__Active' : 'PanelOption__Button'} onClick={() => setSignout(true)}>
                <div className='PanelOption__Button__Icon' style={{ backgroundImage: `url('${icon}')` }} />
                <div className='PanelOption__Button__Text'>{option}</div>
            </div>

            :
            
            <Link className={currentPage ? 'PanelOption__Button__Active' : 'PanelOption__Button'} to={link && link} >
                <div className='PanelOption__Button__Icon' style={{ backgroundImage: `url('${icon}')` }} />
                <div className='PanelOption__Button__Text'>{option}</div>
            </Link>}
        </div>
    )
}
